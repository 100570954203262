<template>
<!-- 购物车 -->
  <div class="shopCar" v-loading="downLoading"   element-loading-text="正在下载中,请耐心等待"
    element-loading-background="rgba(250,250,250,.8)">
      <el-table
    ref="multipleTable"
    :data="shopCar"
    tooltip-effect="dark"
     @selection-change="handleSelectionChange"
    style="width: 100%;margin-bottom:30px;min-height:350px">
    <template slot="empty">
       <el-image
      :src="url"
      fit="fit"></el-image>
        <p class="emptyText">购物车空空如也~</p>
    </template>

      <el-table-column
      type="selection"
      width="60"
      align="right">
    </el-table-column>
     <el-table-column
      prop="outerGoodsCode"
      label="商品型号"
      align="center">
    </el-table-column>
    <el-table-column
      label="商品图片"
      width="250"
      align="center">
      <template slot-scope="scope">
          <!-- <el-image :src="scope.row.skuImageUrl" fit="fill" class="Img" @click="toDetails(scope.row.classifyId)"></el-image> -->
          <el-popover
          placement="right"
    width="650"
    trigger="hover">
    <el-image  :src="scope.row.skuImageUrl" fit="fill" class="BigImg"></el-image>
    <el-image slot="reference" :src="scope.row.skuImageUrl" fit="fill" class="Img" @click="toDetails(scope.row.classifyId)"></el-image>
  </el-popover>
      </template>
    </el-table-column>
    <el-table-column
      prop="skuName"
      label="商品参数"
      align="center">
    </el-table-column>
    <el-table-column
      prop="skuPrice"
      label="单价"
      align="center"
     >
     <template slot-scope="scope">
          <span>{{scope.row.skuPrice ? scope.row.skuPrice :'--'}}</span>
      </template>
    </el-table-column>
    <el-table-column
      prop="skuUnitName"
      label="单位"
      align="center"
     >
    </el-table-column>
     <el-table-column
      prop="skuNum"
      label="数量"
      align="center"
      >
        <template slot-scope="scope">
            <el-input-number
              v-model="scope.row.skuNum"
              :min="1"
              size="mini"
              @change="editSku"
            ></el-input-number>
      </template>
    </el-table-column>
     <el-table-column
      label="小计"
      align="center">
        <template slot-scope="scope">
           <span>{{scope.row.skuNum * scope.row.skuPrice}}</span>
      </template>
    </el-table-column>
    <el-table-column label="操作" align="center">
      <template slot-scope="scope">
        <el-button
          size="mini"
          type="danger"
          @click="del(scope.$index)"
         >删除</el-button>
      </template>
    </el-table-column>
     
  </el-table>
    <div class="fixedFoot displayRow" :class="scrollBottomH ? 'fixedHide' : ''" v-if="shopCar.length > 0">
        <p>已选商品<span class="importSty">{{checkList.length}}</span>件</p>
        <p style="margin:0 50px 0 15px">合计<span class="importSty">{{AllCount}}</span>元</p>
        <div>
            <el-button type="primary" @click="downList" plain style="margin-right:40px">下载清单</el-button>
            <el-button type="primary" @click="saveOrder">提交订单</el-button>
        </div>
    </div>

    <!-- 收获地址展示框 -->
     <el-dialog title="请选择收货地址" :visible.sync="dialogShow">
     <div v-loading="isLoading">
     <div v-if="AddressList.length">
        <ul>
          <li v-for="(item,index) in AddressList" :key="index" class="addressItem" 
          :class="active === index ? 'activedAddress' : ''"
          @click="changeAddress(index)">
            <div class="left displayRow">
            <span class="fontMiddle">{{ item.realName }}</span>
            <el-tag type="warning" class="default" v-show="item.isDefault === 1">默认地址</el-tag>
            <el-tag type="success" class="aligs" v-show="item.alias">{{item.alias}}</el-tag>
          </div>
                     
                     
          <div class="item">
                          <span class="label">地址：</span>              
            <span calss="labelVal">{{ item.province }}{{ item.city }}{{ item.district }}{{item.town}}{{item.detail}}</span>            
          </div>
                     
          <div class="item">
                          <span class="label">手机：</span>              
            <span calss="labelVal">{{ item.phone }}</span>            
          </div>
          </li>
        </ul>
          <div slot="footer" class="dialog-footer">
        <el-button @click="dialogShow = false">取 消</el-button>
        <el-button @click="addAddress">新 增 地 址</el-button>
        <el-button type="primary" @click="sureAddress">确 定</el-button>
      </div>
      </div>
      <div v-else class="textCenter">
           <el-image
      :src="url"
      fit="fit" class="noDataImg"></el-image>
      <p class="noAddressText">暂无收货地址,赶紧去 <span class="themeColor pointer" @click="toAddress">新增一个 </span>吧</p>
      </div>
      </div>
     </el-dialog>
  </div>
</template>
<script>
var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("scrcheckListgoodsTitleipt");
  hm.src = "https://hm.baidu.com/hm.js?5d6b1b39fc8f63765c4a2e30df4bd41d";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();
</script>
<script>
import Bus from '@/assets/js/bus.js'
import {getAddressList,submitOrder,exportOrder,getCar,addCar} from '@/assets/js/api.js'
import {nanoid} from 'nanoid';
export default {
    data(){
        return{
              userInfo:{},
              shopCar:[],
              scrollBottomH:true,
              checkList:[],
              url:require('@/assets/image/noData.png'),
              dialogShow:false,
              AddressList:[],
              active:0,
              isLoading:false,
              downLoading:false,
              BtnType:'',
               }
    },
    computed:{
      AllCount(){ 
          let sum = this.checkList.reduce((c, R) => c + (R.skuPrice * R.skuNum), 0)
          return sum.toFixed(2);
      }

    },
    components:{

    },
    methods:{
      //获取购物车
      getCatList(){
        getCar(this.userInfo.token).then(res =>{
          if(res.data.code === 200){
          this.shopCar = res.data.result === null ? [] : res.data.result.cartList;
          }
        })
      },

      editSku(){
          let params = {cartList:this.shopCar}
        addCar(params,this.userInfo.token).then(res =>{
          if (res.data.code === 200) {
            this.getCatList();
          }
      })
      },

      //回到详情页
  toDetails(classifyId){
    let url= this.$router.resolve({
    name: "DetailsData",  
       query:{
        classifyId:classifyId
      }
  });
   window.open(url.href, "_blank");
  },
      //提交订单 去获取收货地址
      saveOrder(){
        if(this.checkList.length == 0){
             this.$message({
                message: '您还没有选择商品',
                type: 'warning'
              });
              return;
        }
        if(!this.userInfo.token){
           this.$confirm('您还没有登录呢,请先登录', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.toLogin();
        }).catch(() => {
                   
        });
        return;
        }
        this.isLoading = true;
          getAddressList(this.userInfo.token).then((res) => {
      if (res.data.code === 200) {
        this.AddressList = res.data.result;
      }
    }).finally(() =>{
      this.isLoading = false;
    })
        this.dialogShow = true;
      },
          
  // 去登录
    toLogin(){
          let path = this.$route.fullPath;
          let nanoidStr = nanoid();
          localStorage.setItem(nanoidStr,path);
          let url = `https://qiandian8888.com/soutu-server/sys/thirdLogin/render/wechat_open?state=${nanoidStr}`;
          window.location.href = url;
    },
    // 去我的收货地址
    toAddress(){
      this.$router.push('/MyAddress');
    },
      sureAddress(){
        this.dialogShow = false;
        if(this.BtnType === 'Down'){
          this.downOrder();
        }else{
          this.Order();
        }
      },
      addAddress(){
        this.$router.push('/MyAddress');
      },
      changeAddress(index){
        this.active = index;
      },

      // 下载
      downList(){
             if(this.checkList.length == 0){
             this.$message({
                message: '您还没有选择商品',
                type: 'warning'
              });
              return;
        }
        if(!this.userInfo.token){
           this.$confirm('您还没有登录呢,请先登录', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.toLogin();
        }).catch(() => {
                   
        });
        return;
        }
        this.isLoading = true;
          getAddressList(this.userInfo.token).then((res) => {
      if (res.data.code === 200) {
        this.BtnType = 'Down';
        this.AddressList = res.data.result;
      }
    }).finally(() =>{
      this.isLoading = false;
    })
        this.dialogShow = true;
      },
        
   
     
downOrder(){
   this.downLoading = true;
  let address = this.AddressList[this.active];
        let data = {
          orderItemList:this.checkList,
          realName:address.realName,
          userAddress:`${address.province }${ address.city }${ address.district}${address.town}${address.detail}`,
          userPhone:address.phone,
        }
        exportOrder(data).then(res =>{
             //创建一个隐藏的a连接，
    const link = document.createElement('a');
    let blob = new Blob([res.data], {type: 'application/vnd.ms-excel'});
    link.style.display = 'none';
    //设置连接
    link.href = URL.createObjectURL(blob);
    link.download = `购物车清单`; 
    document.body.appendChild(link);
    //模拟点击事件
    link.click();
        }).finally(() =>{
          this.downLoading = false;
        })
},
      //提交订单
      Order(){
        let address = this.AddressList[this.active];
        let data = {
          orderItemParamList:this.checkList,
          realName:address.realName,
          userAddress:`${address.province }${ address.city }${ address.district}${address.town}${address.detail}`,
          userPhone:address.phone,
        }
        submitOrder(data,this.userInfo.token).then(res =>{
          if(res.data.code === 200){
             this.$message({
          message: '订单提交成功',
          type: 'success'
        });
        this.$router.push('/MyOrder');
          }
        })
      },
      handleSelectionChange(val){
        this.checkList = val;
      },
      // 删除商品
        del(index){
        this.$confirm('确定删除此商品?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
           this.shopCar.splice(index,1);  
           this.editSku();
        }).catch(() => {
               
        });
        },

        handleScroll() {
   //变量scrollTop是滚动条滚动时，距离顶部的距离
    //变量scrollTop是滚动条滚动时，距离顶部的距离
   let scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
   
   //变量windowHeight是可视区的高度
   let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
  
   //变量scrollHeight是滚动条的总高度
   let scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
   
    //滚动条到底部的条件
    if(scrollHeight - scrollTop < 928){
      this.scrollBottomH = true
    }else{
       this.scrollBottomH = false;
    }
    }
    },
    watch:{
      'shopCar.length':{
        immediate:true,
        handler(val){
         Bus.$emit('e-shopCarLen',val);
        }
      }
    },
    mounted(){
      addEventListener('scroll', this.handleScroll)//监听函数
    },
    created(){
      this.userInfo  = localStorage.getItem('UserInfo') ? JSON.parse(localStorage.getItem('UserInfo')):{};
      this.getCatList();
    }
}
</script>

<style scoped>
.shopCar{
 margin:0 20px;
}
.Img{
    height: 100px;
    cursor: pointer;
}
.BigImg{
  width:650px;
  height: 650px;
}
.fixedFoot{
    width:100%;
    padding:20px 50px;
    background-color: #25374b;
    text-align: right;
    color:#fff;
    justify-content: flex-end;
    position: fixed;
    bottom: 0;
    left:0px;
    z-index:100;
    box-sizing: border-box;
}
.fixedHide{
  position: relative;

}
.importSty{
    color:#ef883b;
    font-weight:bold;
    font-size: 22px;
    padding:0  5px;
}
.addressItem{
  padding:10px;
  border-radius: 10px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  line-height: 25px;
  font-size: 12px;
}
.activedAddress{
  border:1px solid #409eff;
  box-shadow: 0 2px 4px rgba(64,158,255, .12), 0 0 6px rgba(64,158,255, .04);
}
.noDataImg{
  width:100px;
  margin-bottom: 20px;
}
</style>

